// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration } from "@remix-run/react";
import { typedjson, useTypedLoaderData } from "remix-typedjson";
import { ExternalScripts } from "remix-utils/external-scripts";
import { commitSession, getSession } from "~/models/message.server";
import tailwindStylesheetUrl from "~/tailwind.css";
import { RouteErrorDisplay } from "./components/ErrorDisplay";
import { HighlightInit } from "./components/HighlightInit";
import { AppContainer, MainCenteredContainer } from "./components/layout/AppLayout";
import { Toast } from "./components/primitives/Toast";
import { env } from "./env.server";
import { featuresForRequest } from "./features.server";
import { useHighlight } from "./hooks/useHighlight";
import { usePostHog } from "./hooks/usePostHog";
import { getUser } from "./services/session.server";
import { appEnvTitleTag } from "./utils";
export const links = () => {
  return [{
    rel: "stylesheet",
    href: tailwindStylesheetUrl
  }];
};
export const meta = ({
  data
}) => {
  const typedData = data;
  return [{
    title: `Trigger.dev${appEnvTitleTag(typedData.appEnv)}`
  }, {
    name: "viewport",
    content: "width=1024, initial-scale=1"
  }, {
    name: "robots",
    content: typedData.features.isManagedCloud ? "index, follow" : "noindex, nofollow"
  }];
};
export const loader = async ({
  request
}) => {
  const session = await getSession(request.headers.get("cookie"));
  const toastMessage = session.get("toastMessage");
  const posthogProjectKey = env.POSTHOG_PROJECT_KEY;
  const highlightProjectId = env.HIGHLIGHT_PROJECT_ID;
  const features = featuresForRequest(request);
  return typedjson({
    user: await getUser(request),
    toastMessage,
    posthogProjectKey,
    highlightProjectId,
    features,
    appEnv: env.APP_ENV,
    appOrigin: env.APP_ORIGIN
  }, {
    headers: {
      "Set-Cookie": await commitSession(session)
    }
  });
};
export const shouldRevalidate = options => {
  if (options.formAction === "/resources/environment") {
    return false;
  }
  return options.defaultShouldRevalidate;
};
export function ErrorBoundary() {
  return <>
      <html lang="en" className="h-full">
        <head>
          <meta charSet="utf-8" />

          <Meta />
          <Links />
        </head>
        <body className="bg-darkBackground h-full overflow-hidden">
          <AppContainer>
            <MainCenteredContainer>
              <RouteErrorDisplay />
            </MainCenteredContainer>
          </AppContainer>
          <Scripts />
        </body>
      </html>
    </>;
}
_c = ErrorBoundary;
function App() {
  _s();
  const {
    posthogProjectKey,
    highlightProjectId
  } = useTypedLoaderData();
  usePostHog(posthogProjectKey);
  useHighlight();
  return <>
      {highlightProjectId && <HighlightInit projectId={highlightProjectId} tracingOrigins={true} networkRecording={{
      enabled: true,
      recordHeadersAndBody: true
    }} />}


      <html lang="en" className="h-full">
        <head>
          <Meta />
          <Links />
        </head>
        <body className="bg-darkBackground h-full overflow-hidden">
          <Outlet />
          <Toast />
          <ScrollRestoration />
          <ExternalScripts />
          <Scripts />
          <LiveReload />
        </body>
      </html>
    </>;
}
_s(App, "k2k9xjsvpR41ObUZ0hOsvemkQz0=", false, function () {
  return [useTypedLoaderData, usePostHog, useHighlight];
});
_c2 = App;
export default App;
var _c, _c2;
$RefreshReg$(_c, "ErrorBoundary");
$RefreshReg$(_c2, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;